<template>
  <div id="kaochang-moni-container-wrap">
    <div class="kaochang-moni-container" v-if="inited">
      <div class="flex timu-all-container">
        <div class="info-container">
          <div class="group-container kaoshi">
            <div class="label">理论考试</div>
            <div class="text">第01考台</div>
          </div>
          <div class="group-container user">
            <div class="label">考生信息</div>
            <div class="avatar-container">
              <img
                :src="(mine && mine.avatarUrl )? mine.avatarUrl : 'http://down.qwdata.vip/app_icon/1672758529606'"
                mode="aspectFill" class="avatar">
            </div>
            <div class="row">
              <div class="key">姓名：</div>
              <div class="value">{{ (mine && mine.name) || '游客' }}</div>
            </div>
            <div class="row">
              <div class="key">类别：</div>
              <div class="content" v-if="mine.carType === 1">小车</div>
              <div class="content" v-else-if="mine.carType === 2">货车</div>
              <div class="content" v-else-if="mine.carType === 3">客车</div>
            </div>
            <div class="row">
              <div class="key">科目：</div>
              科目{{ kemu == 1 ? '一' : '四' }}
            </div>
            <div class="row" v-if="title">
              <div class="key">题数：</div>
              {{ list.length }}
            </div>
            <div class="row" v-if="title">
              <div class="key">内容：</div>
              {{ title }}
            </div>
          </div>
          <div class="group-container time" v-if="isKaoshi">
            <div class="label">剩余时间</div>
            <div class="text">{{ timeFormat(max - time) }}</div>
          </div>
          <div class="group-container time" v-else>
            <div class="label">练习时间</div>
            <div class="text">{{ timeFormat(time) }}</div>
          </div>
        </div>
        <div class="question-container">
          <div class="group-container timu" :style="{fontSize: fontSize+'px'}">
            <div class="label">考试题目</div>
            <div style="height: 100%;overflow: hidden;margin: 0 30px">
              <div v-if="question">
                <div class="title">{{ question.title }}</div>
                <div v-if="question.type!==3">
                  <div class="option">A. {{ question.option1 }}</div>
                  <div class="option">B. {{
                      question.option2
                    }}
                  </div>
                  <div class="option" v-if="question.option3">C. {{
                      question.option3
                    }}
                  </div>
                  <div class="option" v-if="question.option4">D. {{
                      question.option4
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="answer-panel">
          <div class="row">
            <div class="timu-container bg left">题目</div>
            <div class="timu-container bg top" v-for="(n, i) in colCount" :key="n">{{ i + 1 }}列</div>
          </div>
          <div class="row-container">
            <div class="row" v-for="(list, rowIndex) in grid" :key="'row_'+rowIndex">
              <div class="timu-container bg left">{{ rowIndex + 1 }}行</div>
              <div class="col timu-container" v-for="(item, colIndex) in list" :key="'col_'+rowIndex+'_'+colIndex"
                   @click="select(item._index)">
                <div :class="[currentIndex===item._index?'current':'normal']">
                  <div v-if="!item.answer">{{ item._index + 1 }}</div>
                  <div v-else-if="item.answer.status" class="success">
                    <div v-if="item.question.type !== 3" style="font-size: 20px">{{ item.answer.result }}</div>
                    <div v-else style="font-size: 20px">{{ item.answer.result === 'A' ? '√' : '×' }}</div>
                  </div>
                  <div v-else class="fail">
                    <div v-if="item.question.type !== 3" style="font-size: 20px">{{ item.answer.result }}</div>
                    <div v-else style="font-size: 20px">{{ item.answer.result === 'A' ? '√' : '×' }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-row">
        <div class="left-bottom-block"></div>
        <div class="pic-row">
          <div class="answer-container" v-if="question">
            <div class="tips">
              您选择的答案：
              <div v-if="question.type ===3 && selected">
                {{ selected === 'A' ? '√' : '×' }}
              </div>
              <div v-else>{{ selected }}</div>
            </div>
            <div class="m-r-10">选项：</div>
            <div v-if="question.type!=3" class="flex">
              <div :class="['btn', activeOption('A')]" @click="selectOption('A')">A</div>
              <div :class="['btn', activeOption('B')]" @click="selectOption('B')">B</div>
              <div :class="['btn', activeOption('C')]" v-if="question.option3" @click="selectOption('C')">C</div>
              <div :class="['btn', activeOption('D')]" v-if="question.option4" @click="selectOption('D')">D</div>
            </div>
            <div v-else class="flex">
              <div :class="['btn', activeOption('A')]" @click="selectOption('A')">√</div>
              <div :class="['btn', activeOption('B')]" @click="selectOption('B')">×</div>
            </div>
          </div>
          <div class="process-row">
            <div class="tips">
              <div class="tips1">操作提示：{{ question ? question.typeStr : '' }}</div>
              <div class="tips2">请选择你认为正确的答案！</div>
            </div>
            <div :class="['btn', isFirst? '': 'white']" @click="prevQuestion()">上一题</div>
            <div :class="['btn', isEnd? '': 'white']" @click="nextQuestion()">下一题</div>
            <div class="btn white" @click="trySubmit" v-if="isKaoshi">交卷</div>
          </div>
          <div class="f1 flex v vc hc">
            <el-image :src="img" v-if="img"
                      :preview-src-list="[img]"
                      :close-on-press-escape="true"
                      :preview-teleported="true"
                      :hide-on-click-modal="true"
                      class="cover"></el-image>
            <div class="cover" v-else></div>
          </div>
        </div>
        <div class="right-bottom-block">
          <div class="row">
            <div class="read-btn" @click="showExplain = true">速记</div>
            <div class="read-btn" @click="showSkill = true">官方解析</div>
            <div class="read-btn" v-if="question.law" @click="showLaw = true">相关法规</div>
          </div>
          <div class="row">
            <div :class="['read-btn', reading?'reading':'']" @click="playMusic('title')">读题</div>
            <div class="read-btn" @click="toUnCollect" v-if="question.collect">已收藏</div>
            <div class="read-btn" @click="toCollect" v-else>收藏</div>
          </div>
          <div class="row">
            <div class="read-btn" @click="changeFontSize(1)">字体+</div>
            <div class="read-btn" @click="changeFontSize(0)">字体-</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <SubmitDialog title="速记" v-if="showExplain && question" :selected="selected" :question="question"
                showDownTime="close"
                @close="showExplain = false">
    <template v-slot>
      <template v-if="question.explain">
        <div class="dialog-tips">{{ question.explain }}</div>
      </template>
    </template>
  </SubmitDialog>
  <SubmitDialog title="官方解析" v-if="showSkill && question" :selected="selected" :question="question"
                showDownTime="close"
                @close="showSkill = false">
    <template v-slot>
      <template v-for="(skill, n) in question.skills" :key="skill.title">
        <div class="dialog-tips">技巧{{ n + 1 }}：</div>
        <div class="dialog-tips">{{ skill.title }}</div>
        <el-image :src="skill.imgUrl" v-if="skill.imgUrl" fit="contain" style="height: 200px;"
                  :preview-src-list="[skill.imgUrl]"></el-image>
      </template>
    </template>
  </SubmitDialog>
  <SubmitDialog title="相关法规" v-if="showLaw && question" :selected="selected" :question="question"
                showDownTime="close"
                @close="showLaw = false">
    <template v-slot>
      <template v-if="question.law">
        <div class="dialog-tips">{{ question.law }}</div>
      </template>
    </template>
  </SubmitDialog>
  <SubmitDialog title="考试确认" v-if="showConfirm && question">
    <template v-slot>
      <div class="dialog-tips">操作提示：</div>
      <div class="dialog-tips">
        您当前考试答对{{ zhengqueCount }}题，答错{{ cuowuCount }}题，未答{{
          list.length - zhengqueCount - cuowuCount
        }}题
      </div>
      <div class="dialog-tips">1.点击【确认交卷 】，将提交考试成绩，考试结束！</div>
      <div class="dialog-tips">2.点击【继续考试 】，将关闭本窗口，继续考试！</div>
    </template>
    <template v-slot:footer>
      <div class="flex h vc hc">
        <div class="dialog-btn" @click="showResultDialog">
          确认交卷
        </div>
        <div class="dialog-btn" @click="continueKaoshi">
          继续考试
        </div>
      </div>
    </template>
  </SubmitDialog>
  <SubmitDialog title="信息提示" v-if="showResult && question" showDownTime="exit" @exit="toExit">
    <template v-slot>
      <div class="dialog-tips">姓名：{{ (mine && mine.name) || '游客' }}</div>
      <div class="dialog-tips" v-if="result.status">您本次模拟考试得分{{ result.score }}分，恭喜考试及格！</div>
      <div class="dialog-tips" v-if="!result.status">您本次模拟考试得分{{ result.score || 0 }}分，不及格！</div>
      <div class="dialog-tips" v-if="!result.status">祝您下次考试成功！</div>
    </template>
    <template v-slot:footer>
      <div class="flex h vc hc">
        <div class="dialog-btn" @click="toExit">
          关闭
        </div>
        <div class="dialog-btn" @click="toWrong">
          查看错题
        </div>
      </div>
    </template>
  </SubmitDialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { commonApi, questionApi } from '@/api'
import utils from '@/libs/utils'
import SubmitDialog from '@/components/SubmitDialog.vue'
import Storage from '@/libs/Storage'

export default {
  components: { SubmitDialog },
  data () {
    return {
      inited: false,
      showSkill: false,
      showLaw: false,
      showExplain: false,
      kemu: null,
      yuyin: null,
      max: 45 * 60,
      // max: 5,
      colCount: 10,
      time: 0,
      list: [],
      selected: '',
      title: null,
      type: 'kaochang',
      question: null,
      currentIndex: 0,
      showConfirm: false,
      showResult: false,
      showError: false,
      reading: false,
      zhengqueCount: 0,
      cuowuCount: 0,
      result: {},
      today: null,
      fontSize: 20,
      random: 0
    }
  },
  created () {
    this.kemu = this.$route.query.kemu
    this.type = this.$route.query.type
    this.random = this.$route.query.random || 0
    this.today = parseInt(this.$route.query.today || 0)
    this.init()
    if (this.isKaoshi) {
      this._timeDown = setInterval(() => {
        console.log('xxxxxx')
        if (this.time >= this.max) {
          console.log('结束考试')
          this.showResultDialog()
          clearInterval(this._timeDown)
          return
        }

        this.time += 1
      }, 1000)
    } else {
      this._timeDown = setInterval(() => {
        this.time += 1
      }, 1000)
    }
  },
  unmounted () {
    if (this._timeDown) {
      clearInterval(this._timeDown)
    }
  },
  computed: {
    ...mapGetters(['mine']),
    isKaoshi () {
      if (this.type === 'kaochang') {
        return 1
      }
      return 0
    },
    grid () {
      const list = []

      let row = []
      this.list.map((item, n) => {
        item._index = n
        if (n % this.colCount === 0) {
          // console.log('xxxxxxxxx row', row.length, row)
          if (row.length) {
            list.push(row)
          }
          row = []
        }
        row.push(item)
      })
      if (row.length) {
        list.push(row)
      }
      // console.log('=======', list.length)
      // this.list.splice(0, 10)
      return list
    },
    isFirst () {
      return this.currentIndex === 0
    },
    isEnd () {
      return this.currentIndex + 1 >= this.list.length
    },
    img () {
      if (this.question) {
        if (this.question.img) {
          return this.question.img
        }
        if (this.question.signIcon) {
          return this.question.signIcon.img
        }
      }
      return ''
    }
  },
  methods: {
    ...mapActions('question', ['questionCollect', 'questionUncollect']),
    timeFormat (time) {
      let minutes = Math.floor((time) / 60) + ''
      if (minutes.length < 2) {
        minutes = '0' + minutes
      }
      let seconds = (time) % 60 + ''
      if (seconds.length < 2) {
        seconds = '0' + seconds
      }
      return minutes + ':' + seconds
    },
    async init () {
      if (this.type.indexOf('category_') === 0) {
        const res = await questionApi.mijuanInfo(this.type.split('_')[1])
        this.title = res.data.data.title
      } else if (this.type.indexOf('tag_') === 0) {
        const res = await questionApi.tagInfo(this.type.split('_')[1])
        this.title = res.data.data.title
      } else if (this.type === 'kaochang') {
        this.title = '模拟考试'
      } else if (this.type === 'jingxuan') {
        this.title = '精选题库'
      } else if (this.type === 'shunxu') {
        this.title = '顺序练习'
      } else if (this.type === 'collect') {
        if (this.today) {
          this.title = '今日收藏'
        } else {
          this.title = '全部收藏'
        }
      } else if (this.type === 'wrong') {
        if (this.today) {
          this.title = '今日错题'
        } else {
          this.title = '全部错题'
        }
      }
      let res
      if (this.type === 'collect') {
        const userId = this.$route.query.userId
        res = await questionApi.collects(this.kemu, this.today, userId)
      } else if (this.type === 'wrong') {
        res = await questionApi.wrongs(this.kemu, this.today)
      } else if (this.type.startsWith('story_')) {
        res = await questionApi.list(this.type, this.kemu, null, 'kaoshi')
      } else {
        res = await questionApi.list(this.type, this.kemu, null)
      }
      const list = []
      res.data.data.list.map(item => {
        list.push({
          params: item,
          answer: null
        })
      })
      this.list = list
      this.currentIndex = 0
      await this.initQuestion()
      this.fontSize = await Storage.get('fontSize', 20)
      this.inited = true
    },
    select (i) {
      this.currentIndex = i
      this.initQuestion()
    },
    async initQuestion () {
      if (!this.list[this.currentIndex]) {
        return
      }

      const id = this.list[this.currentIndex].params.id

      const res = await questionApi.info(id)
      const res2 = await questionApi.yuyinAll(id)
      this.yuyin = res2.data.data

      const question = res.data.data
      if (question.type === 1) {
        question.typeStr = '单选题'
      } else if (question.type === 2) {
        question.typeStr = '多选题'
      } else if (question.type === 3) {
        question.typeStr = '判断题'
      }
      question.answer += ''
      const answer = question.answer
      let answerStr = ''
      if (answer.indexOf('1') > -1) {
        answerStr += 'A'
      }
      if (answer.indexOf('2') > -1) {
        answerStr += 'B'
      }
      if (answer.indexOf('3') > -1) {
        answerStr += 'C'
      }
      if (answer.indexOf('4') > -1) {
        answerStr += 'D'
      }
      question.answerStr = answerStr
      question.result = []
      this.selected = ''
      // const questionStr = '题目：##title##\n选项：A.##op1##\nB.##op2##\nC.##op3##\nD.##op4##\n答案：##answer##\n解释：##explain##'
      let questionStr = '题目：' + question.title
      questionStr += '\n'
      questionStr += '选项：A.' + question.option1
      questionStr += '\n'
      questionStr += 'B.' + question.option2
      questionStr += '\n'
      if (question.option3) {
        questionStr += 'C.' + question.option3
        questionStr += '\n'
        questionStr += 'D.' + question.option4
        questionStr += '\n'
      }

      questionStr += '正确答案：' + question.answerStr
      questionStr += '\n'

      const skills = []

      if (question.explainStr) {
        skills.push(question.explainStr)
      }
      //
      // question.skills.map(skill => {
      //   skills.push(skill.title)
      // })

      skills.map((skill, n) => {
        questionStr += '解析' + (n + 1) + '：' + skill
        questionStr += '\n'
      })

      console.log(questionStr)

      question.title = (1 + this.currentIndex) + '.' + question.title
      this.list[this.currentIndex].question = question
      if (this.list[this.currentIndex].answer) {
        this.selected = this.list[this.currentIndex].answer.result
      }
      this.question = question
      this.x = 0
    },
    activeOption (option) {
      return this.selected.indexOf(option) > -1 ? 'active' : ''
    },
    selectOption (option) {
      if (this.question.type === 2) {
        const i = this.selected.indexOf(option)
        let arr
        if (i > -1) {
          arr = this.selected.split('')
          arr.splice(i, 1)
        } else {
          this.selected += option
          arr = this.selected.split('')
        }
        this.selected = arr.sort().join('')
      } else {
        this.selected = option
      }
      console.log(this.question.type, this.selected)
      // this.list[this.currentIndex].answer = {
      //   result,
      //   status: this.question.answerStr === result
      // }
      // setTimeout(() => {
      //   this.nextQuestion()
      // }, 500)
    },
    prevQuestion () {
      if (this.currentIndex > 0) {
        this.currentIndex--
        this.initQuestion()
      }
    },
    async nextQuestion () {
      if (await this.submitAnswer()) {
        this.requestNextQuestion()
      }
    },
    async submitAnswer () {
      if (!this.list[this.currentIndex].answer && this.selected) {
        const status = this.question.answerStr === this.selected
        this.list[this.currentIndex].answer = {
          result: this.selected,
          status
        }
        try {
          // await this.questionAnswer({
          //   type: 'kaochang',
          //   id: this.question.id,
          //   result: this.selected,
          //   status: status ? 1 : -1,
          //   index: this.index,
          //   kemu: this.kemu
          // })
          // this.answer()
        } catch (e) {

        }
        if (status) {
          this.zhengqueCount++
        } else {
          this.cuowuCount++
          this.showError = true
          return false
        }
      }

      return true
    },
    changeFontSize (action) {
      if (action) {
        this.fontSize++
      } else {
        this.fontSize--
      }
      Storage.set('fontSize', this.fontSize)
    },
    async toCollect () {
      await this.questionCollect({
        id: this.question.id,
        kemu: this.kemu
      })
      this.question.collect = 1
    },
    async toUnCollect () {
      await this.questionUncollect({
        id: this.question.id,
        kemu: this.kemu
      })
      this.question.collect = 0
    },
    async playMusic (key) {
      if (this.reading) {
        return
      }

      const audio = utils.playMusic(this.yuyin[key])
      audio.onplay = () => {
        console.log('play')
        this.reading = true
      }
      audio.onended = () => {
        console.log('onended')
        this.reading = false
      }
      await audio.play()
    },
    requestNextQuestion () {
      if (this.currentIndex < this.list.length - 1) {
        this.currentIndex++
        setTimeout(() => {
          this.initQuestion()
        }, 300)
      }
    },
    async trySubmit () {
      // await this.submitAnswer()
      this.showConfirm = true
      console.log('trySubmit')
    },
    continueKaoshi () {
      this.showConfirm = false
    },
    showResultDialog () {
      // eslint-disable-next-line eqeqeq
      const score = this.kemu == 1 ? this.zhengqueCount : this.zhengqueCount * 2
      this.result = {
        score,
        status: score >= 90
      }
      this.showConfirm = false
      this.showResult = true

      // this.kaoshiSubmit({
      //   type: this.type,
      //   score,
      //   status: this.result.status,
      //   time: this.time,
      //   kemu: this.kemu,
      //   zhengque: this.zhengqueCount,
      //   cuowu: this.cuowuCount,
      // })
    },
    toExit () {
      console.log('toExit')
      // this._forceExitExamTime = Date.now()
      // uni.navigateBack()
      this.showResult = false
    },
    closeErrorDialog () {
      this.showError = false
      if (this.list[this.currentIndex].answer) {
        this.requestNextQuestion()
      }
    }
  }
}
</script>

<style scoped lang="less">
.dialog-tips {
  font-size: 16px;
  color: #343630;
  margin-bottom: 10px;
  line-height: 1.8rem;
  white-space: pre-wrap;
}

.dialog-btn {
  width: 140px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  font-size: 15px;
  color: #000000;
  margin-left: 60px;
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }
}

#kaochang-moni-container-wrap {
  background-color: #f5f1ef;
  height: 100%;

  .kaochang-moni-container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .timu-all-container {
      flex: 1;
    }

    .info-container {
      display: flex;
      flex-direction: column;
      width: 250px;
      margin-left: 10px;
    }

    .group-container {
      border: 1px solid #cdcdcd;
      position: relative;
      margin-top: 30px;

      .label {
        position: absolute;
        font-size: 18px;
        color: #062f71;
        top: -12px;
        left: 50px;
        text-align: center;
        right: 50px;
        font-weight: normal;
        background-color: #f5f1ef;
        z-index: 10;
      }

      .text {
        margin-top: 20px;
        font-size: 16px;
      }

      &.kaoshi {
        height: 50px;
        font-size: 16px;
        text-align: center;
      }

      &.time {
        height: 50px;
        font-size: 10px;
        text-align: center;

        .text {
          font-weight: bold;
          font-size: 18px;
        }
      }

      &.timu {
        margin-left: 10px;
        margin-right: 10px;
        font-size: 20px;
        color: #30322C;
        line-height: 22px;
        padding-top: 25px;
        box-sizing: border-box;
        height: 100%;

        .label {
          position: absolute;
          right: auto;
          top: -15px;
          font-size: 18px;
          padding-left: 20px;
          padding-right: 20px;
        }
      }

      &.user {
        flex: 1;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;

        .avatar-container {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .avatar {
          width: 80px;
          height: 80px;
          border-radius: 86px;
          margin-top: 20px;
        }

        .row {
          display: flex;
          margin-left: 20px;
          margin-right: 10px;
          margin-top: 10px;

          .key {
            white-space: nowrap;
          }

          .value {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    .read-btn {
      font-weight: bold;
      padding: 8px 10px;
      font-size: 16px;
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #30322c;
      background-color: #fff;
      cursor: pointer;
      margin-left: 10px;

      &.reading {
        background-color: #4d8df5;
        color: #fff;
      }

      &:active {
        background-color: #eee;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .question-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      font-weight: bold;

      .title {
        line-height: 1.3;
        margin-bottom: 5px;
      }

      .option {
        line-height: 1.3;
      }
    }

    .answer-panel {
      background-color: #f5f1ef;
      display: flex;
      margin-top: 30px;
      margin-right: 10px;
      flex-direction: column;

      .row-container {
        max-height: 385px;
        overflow: auto;
      }

      .row {
        display: flex;
      }

      .col {
        cursor: pointer;

        &:hover {
          background-color: #f0f0f0;
        }
      }

      .timu-container {
        display: flex;
        white-space: nowrap;
        width: 35px;
        height: 35px;
        font-size: 12px;
        background-color: #fff;
        border-right: 1px solid #cdcdcd;
        border-bottom: 1px solid #cdcdcd;
        box-sizing: border-box;

        &.left {
          &:last-child {
            border-bottom: none;
          }
        }

        &.top {
          &:last-child {
            border-right: none;
          }
        }

        &.bg {
          background-color: #4d8df5;
          display: flex;
          color: #fff;
          justify-content: center;
          align-items: center;
        }

        .normal {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .current {
          flex: 1;
          background-color: #8fc8f8;
          display: flex;
          color: #fff;
          justify-content: center;
          align-items: center;
        }

        .fail {
          flex: 1;
          display: flex;
          color: #f4433b;
          justify-content: center;
          align-items: center;
        }

        .success {
          flex: 1;
          display: flex;
          color: green;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .process-row {
      border-top: 2px solid #4673B0;
      border-bottom: 2px solid #4673B0;
      padding-left: 10px;
      display: flex;
      align-items: center;

      .btn {
        width: 100px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ddd;
        border-radius: 8px;
        color: #30322c;
        margin: 10px;
        background-color: #e9e9e9;
        cursor: pointer;
        font-size: 16px;
        margin-left: 0;
        font-weight: bold;

        &.white {
          background-color: #fff;

          &:active {
            background-color: #f6f6f6;
          }
        }
      }

      .tips {
        flex: 1;

        .tips1 {
          font-size: 16px;
          color: red;
        }

        .tips2 {
          margin-top: 5px;
          font-size: 16px;
          color: #30322C;
        }
      }
    }

    .bottom-row {
      display: flex;
      margin-top: 10px;
      margin-left: 10px;
      margin-right: 10px;

      .left-bottom-block {
        width: 250px;
        border: 1px solid #cdcdcd;
      }

      .right-bottom-block {
        width: 383px;
        border: 1px solid #cdcdcd;

        .row {
          display: flex;
          margin: 10px;
        }
      }

      .pic-row {
        border: 1px solid #cdcdcd;
        margin: 0 10px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;

        .answer-container {
          display: flex;
          padding: 10px 0;
          align-items: center;
          font-size: 16px;
          color: #535353;
          margin-left: 20px;

          .tips {
            color: #0853CD;
            flex: 1;
            display: flex;
            align-items: center;
          }

          .btn {
            width: 40px;
            height: 40px;
            border: 1px solid #ddd;
            font-size: 20px;
            color: #000;
            display: flex;
            justify-content: center;
            background-color: #fff;
            align-items: center;
            margin-right: 10px;
            cursor: pointer;
            border-radius: 5px;

            &.active {
              background-color: #8fc8f8;
              color: #fff;
              border: 1px solid #8fc8f8;
            }
          }
        }

        .cover {
          height: 270px;
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
