<template>
  <div id="kaochang_enter-moni-container-wrap">
    <div class="header">
      <div class="bg2"></div>
      <div :class="['bg', 'kemu'+kemu]">
      </div>
    </div>
    <div class="info">
      <div class="f1">
        <div class="label">考试须知</div>
        <div class="tips">1.遵守考场纪律，服从监考人员指挥。</div>
        <div class="tips">2.进入考场、手机关机。禁止抽烟，禁止吃零食。</div>
        <div class="tips">3.未经工作人员允许，考生禁止随意出入考场。</div>
        <div class="tips">4.考场内禁止大声喧哗，禁止随意走动。 开始考试。</div>
        <div class="tips">5.考试中认真答题，不准交头接耳。</div>
        <div class="tips">6考试中不准冒名顶替，不准弄虚作假。</div>
        <div class="tips">7.注意考场卫生，禁止随地叶痰，禁止乱扔纸屑。</div>
        <div class="tips">8.爱护公物及考试设备。 答案。</div>
      </div>
      <div class="divider"></div>
      <div class="f1">
        <div class="label">理论考试01号考台</div>
        <div class="name">考试姓名：{{ mine.name || '游客' }}</div>
        <div class="name" v-if="title">考试内容：{{ title }}</div>
        <div class="flex v vc hc">
          <div class="start-btn" @click="toMoni">开始考试</div>
        </div>
        <div class="tips2">操作提示:每题考试答案确定后，点击[下一题]
          ，电脑立即判定所选答案，如选择错误，系统将提示正确答案，提示后不允许修改答案。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { questionApi } from '@/api'

export default {
  data () {
    return {
      kemu: null,
      type: null,
      today: null,
      title: null
    }
  },
  async created () {
    this.kemu = this.$route.query.kemu
    this.type = this.$route.query.type || 'kaochang'
    this.today = parseInt(this.$route.query.today || 0)
    this.title = '模拟考试'

    if (this.type.indexOf('category_') === 0) {
      const res = await questionApi.mijuanInfo(this.type.split('_')[1])
      this.title = res.data.data.title
    } else if (this.type.indexOf('tag_') === 0) {
      const res = await questionApi.tagInfo(this.type.split('_')[1])
      this.title = res.data.data.title
    } else if (this.type === 'kaochang') {
      this.title = '模拟考试'
    } else if (this.type === 'jingxuan') {
      this.title = '精选题库'
    } else if (this.type === 'shunxu') {
      this.title = '顺序练习'
    } else if (this.type === 'collect') {
      if (this.today) {
        this.title = '今日收藏'
      } else {
        this.title = '全部收藏'
      }
    } else if (this.type === 'wrong') {
      if (this.today) {
        this.title = '今日错题'
      } else {
        this.title = '全部错题'
      }
    }
  },
  computed: {
    ...mapGetters(['mine'])
  },
  methods: {
    toMoni () {
      // eslint-disable-next-line eqeqeq
      if (this.kemu == 1) {
        this.$router.push({
          path: 'kaochang',
          query: {
            kemu: 1,
            type: this.type,
            today: this.today
          }
        })
      } else {
        this.$router.push({
          path: 'kaochang4',
          query: {
            kemu: 4,
            type: this.type,
            today: this.today
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="less">
#kaochang_enter-moni-container-wrap {
  background-color: #f5f1ef;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    position: relative;
    height: 140px;

    .bg {
      z-index: 1;

      &.kemu1 {
        background-image: url("http://down.qwdata.vip/app_icon/1686626659217");
      }

      &.kemu4 {
        background-image: url("http://down.qwdata.vip/app_icon/1686649560099");
      }

      height: 140px;
      background-repeat: no-repeat;
      background-position: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
    }

    .bg2 {
      height: 36px;
      background-color: #ffffff;
      z-index: 0;
    }

    background-color: #f5f1ef;
  }

  .info {
    border: 1px solid #CDCDCD;
    margin: 10px;
    flex: 1;
    display: flex;
    font-size: 18px;

    .divider {
      width: 1px;
      background-color: #cdcdcd;
    }

    .label {
      margin-left: 30px;
      margin-top: 58px;
      color: #030303;
      font-weight: bold;
      font-size: 22px;
      margin-bottom: 20px;
    }

    .name {
      color: #DF5B3D;
      line-height: 18px;
      margin-left: 30px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    .start-btn {
      font-size: 16px;
      color: #FFFFFF;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #0095E8;
      border-radius: 12px;
      align-self: center;
      width: 180px;
      height: 50px;
      margin-top: 60px;
      margin-bottom: 100px;
      cursor: pointer;
    }

    .tips2 {
      color: #6c6c6b;
      margin-left: 30px;
      line-height: 3rem;
      margin-right: 30px;
    }
  }

  .tips {
    color: #555;
    line-height: 3rem;
    margin-left: 30px;
    margin-right: 30px;
  }
}
</style>
