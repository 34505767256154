export default {
  api: '',
  httpHeaders: {
    jwtSess: 1
    // 'content-type': 'application/json'
  },
  develop: 'http://127.0.0.1:7001',
  // develop: 'https://api.jiakao.qwdata.vip',
  release: 'https://api.jiakao.qwdata.vip',
  authTokenKey: 'adadad',
  title: '后台',
  pwd: 'jia23xia4kaoshi5t2'
}
