<template>
  <div class="login-root">
    <div class="login-header draggable-area">
      <div class="logo-title-text">泛海知识</div>
      <div class="f1"></div>
    </div>
    <div class="bg"></div>
    <div class="bg1"></div>
    <div class="page">
      <div class="info-container">
        <div class="app-name"><div class="safe"></div>驾考内部系统 <span class="version">v6.0</span></div>
        <div class="slogan">直连2024官方题库，驾考通关更轻松！</div>
        <div class="update">更新日期：2024年06月01日</div>
        <div class="juemi"></div>
      </div>
      <div class="form-container">
        <div class="qrcode-wrapper">
          <vue-qrcode :value="'web_scan:' + qrcode" :options="options"></vue-qrcode>
          <div class="timeout-mask" v-if="timeout" @click="refreshQrcode">
            <div class="icon-btn">
              <div class="icon"></div>
            </div>
            <div class="timeout-tips">
              <div>二维码已过期</div>
              <div>点击重新获取</div>
            </div>
          </div>
        </div>
        <div class="tips">App或小程序扫码登录</div>
      </div>
    </div>
    <div class="footer">北京青文科技有限公司出品 京ICP备19027719号-14</div>
  </div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
import { mapActions } from 'vuex'
import utils from '@/libs/utils'
import { ElLoading } from 'element-plus'

export default {
  components: {
    VueQrcode
  },
  data () {
    return {
      qrcode: '',
      lastTime: 0,
      timeout: true,
      options: {
        width: 250,
        margin: 0,
        color: {
          dark: '#333',
          light: '#ffffff'
        }
      }
    }
  },
  created () {
    this.refreshQrcode()
  },
  methods: {
    ...mapActions(['login']),
    refreshQrcode () {
      this.lastTime = Date.now()
      this.qrcode = (Math.round(Math.random() * 10000000000000)).toString(32).toUpperCase() + (Math.round(Math.random() * 10000000000000)).toString(32).toUpperCase()
      this.startPull()
    },
    async startPull () {
      this.timeout = false
      const dev = 0
      const max = dev ? 5 : 20
      const dTime = dev ? 1000 : 3000

      for (let i = 0; i < max; i++) {
        await utils.sleep(dTime)
        const suc = await this.login(this.qrcode)
        if (suc) {
          const _loading = ElLoading.service({
            lock: true,
            text: '加载中',
            background: 'rgba(0, 0, 0, 0.7)'
          })
          this.$router.replace('/')
          _loading.close()
          return
        }
      }
      this.timeout = true
    },
    async toLogin () {
      const suc = await this.login(this.qrcode)
      if (suc) {
        this.$router.replace('/')
      }
    }
  }
}
</script>

<style scoped lang="less">
.login-root {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0966fb;

  .login-header {
    height: 60px;
    right: 0;
    top: 0;
    z-index: 2;
    display: flex;
    left: 0;
    position: fixed;

    .logo-title-text {
      font-size: 30px;
      font-weight: bolder;
      align-self: center;
      margin-left: 20px;
      color: #fff;
    }
  }
  .bg {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1000px;
    background-position: center;
    background-image: url("@/static/home-bg2.png");
    background-size: cover;
    z-index: -1;
  }
  .bg1 {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 1000px;
    background-position: center;
    background-image: url("@/static/home-bg2.png");
    background-size: cover;
    z-index: -1;
    transform: rotate(180deg);
  }

  .page {
    width: 1200px;
    display: flex;
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 30px;
    color: #fff;
    font-size: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }

  .info-container {
    flex: 1;
    position: relative;
    .safe {
      width: 70px;
      height: 70px;
      background-size: cover;
      background-image: url("@/static/safe3.png");
      margin-right: 5px;
    }

    .juemi {
      width: 150px;
      height: 150px;
      background-size: cover;
      background-image: url("@/static/juemi.png");
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
      transform: rotate(10deg);
    }

    .app-name {
      color: #fff;
      font-size: 70px;
      font-weight: bold;
      display: flex;
      align-items: center;
      .version {
        font-size: 40px;
        font-weight: normal;
        align-self: flex-end;
        margin-left: 15px;
      }
    }

    .slogan {
      color: #fff;
      margin-top: 40px;
      font-size: 40px;
    }

    .update {
      color: rgba(255, 255, 255, 0.8);
      margin-top: 40px;
      font-size: 20px;
    }
  }

  .form-container {
    width: 350px;
    height: 400px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    justify-content: center;
    z-index: 1;

    .qrcode-wrapper {
      position: relative;
      border-radius: 10px;
      overflow: hidden;

      .timeout-mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .icon-btn {
          width: 80px;
          height: 80px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100px;
          box-shadow: 0 5px 5px rgba(92, 92, 92, 0.3);
          cursor: pointer;

          .icon {
            width: 50px;
            height: 50px;
            background-size: contain;
            margin-left: 3px;
            background-image: url("@/static/refresh.png");
          }
        }

        .timeout-tips {
          padding: 10px 20px;
          font-size: 16px;
          margin-top: 10px;
          background-color: #fff;
          line-height: 1.5rem;
        }
      }
    }

    .logo {
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }

    .btn {
      margin-top: 30px;
      width: 300px;
      font-size: 18px;
    }

    .tips {
      text-align: center;
      font-size: 16px;
      margin-top: 20px;
      color: #999;
    }
  }
}
</style>
