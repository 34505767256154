<template>
  <div class="submit-dialog-container">
    <div class="dialog-container">
      <div class="title">{{ title }}</div>
      <div class="body">
        <slot></slot>
      </div>
      <div class="answer2-container" v-if="question">
        <div class="flex f1" v-if="question.type ===3">
          <div class="flex h vc">正确答案：
            <img src="@/static/lan_gou.png" v-if="question.answerStr === 'A'"
                 style="height: 20px;width: 30px">
            <img src="@/static/lan_cha.png" v-else-if="question.answerStr === 'B'"
                 style="height: 20px;width: 18px">
          </div>
          <div style="margin-left: 40px" class="flex h vc" v-if="selected">你的答案：
            <img src="@/static/lan_gou.png" v-if="selected === 'A'"
                 style="height: 20px;width: 30px">
            <img src="@/static/lan_cha.png" v-else-if="selected === 'B'"
                 style="height: 20px;width: 18px">
          </div>
        </div>
        <div class="flex f1" v-else>
          <div>正确答案：{{ question.answerStr }}</div>
          <div style="margin-left: 40px" v-if="selected">你的答案：{{ selected }}</div>
        </div>
        <div class="flex h hc">
          <div class="dialog-btn primary" @click="close">确定</div>
        </div>
      </div>
      <div class="footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title', 'question', 'selected'],
  name: 'SubmitDialog',
  data () {
    return { time: 10 }
  },
  created () {
    console.log('---------', this.question, this.selected)
  },
  unmounted () {
    console.log('destroyed')
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
<style scoped lang="less">
.submit-dialog-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000a;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  .dialog-container {
    background-color: #0E57CD;
    width: 40%;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;

    .title {
      padding: 10px 0;
      font-size: 20px;
      color: #FFFFFF;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .body {
      display: flex;
      flex-direction: column;
      padding: 15px;
      background-color: #fff;
      margin-left: 15px;
      margin-right: 15px;
      border-radius: 5px;
      overflow: hidden;
      box-sizing: border-box;
      font-size: 16px;
    }

    .answer2-container {
      background-color: #fff;
      margin-left: 15px;
      margin-right: 15px;
      border-radius: 5px;
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      padding: 15px;
      font-size: 15px;
      color: #343630;
      box-sizing: border-box;
    }

    .dialog-btn {
      width: 140px;
      height: 40px;
      background-color: #fff;
      border-radius: 5px;
      font-size: 15px;
      color: #000000;
      margin-left: 60px;
      margin-top: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &.primary {
        border: 1px solid #aaa;
      }

      &:first-child {
        margin-left: 0;
      }
    }

    .footer {
      .tips {
        text-align: center;
        color: #fff;
        font-size: 12px;
        margin-top: 20px;
      }
    }
  }
}
</style>
